<template>
  <div class="project-mortgage-decline-lead">
    <fd-form class="card" @submit.prevent="submitForm">
      <h2 class="px-2 py-4">Decline Lead</h2>

      <modal-body class="p-2">
        <fd-textarea
          v-model="form.description"
          class="col-12 px-1 mb-2"
          label="Remark"
          name="declineRemark"
          type="text"
          :validators="[validator.required]"
        >
        </fd-textarea>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <button type="button" class="btn" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn danger ml-1">Decline</button>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      form: {
        description: ""
      },
      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      this.$emit("submit", this.form);
    }
  }
};
</script>

<style lang="scss">
.project-mortgage-decline-lead {
  min-width: 450px;
}
</style>

